import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';




import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AuthService } from './services/auth.service';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';
import { ResizeService } from './services/resize.service';
import { DatePipe, CommonModule } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import {  MatRippleModule } from '@angular/material/core';

import {MatBadgeModule} from '@angular/material/badge';
registerLocaleData(localeFr);

@NgModule({
  declarations: 
  [
    AppComponent,
    HeaderComponent,
    WelcomeComponent,

    
  ],

  imports: 
  [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,   
    MatIconModule,
    MatTooltipModule,   
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatRippleModule,
    MatBadgeModule
  ],

  providers: 
  [
   { provide: LOCALE_ID, useValue: 'fr' },
   AuthService,
   AuthGuardService,
   AdminGuardService,
   ResizeService,

  ],

  entryComponents: [],
  bootstrap: [AppComponent]

})

export class AppModule { }