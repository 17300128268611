import { Component, OnInit } from '@angular/core';
import{ Router, NavigationEnd} from '@angular/router';
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit  {
  title = 'Isanama';
  go : boolean= false;
  constructor( public router: Router  ) {




    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {   
        gtag('config', 'UA-165813552-1', {'page_path': event.urlAfterRedirects });
        }
      });
    }

ngOnInit(): void {




  setInterval(() => {
    this.go=true; 
  }, 1000);;}


}





