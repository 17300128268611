

<mat-toolbar color="primary"   class="mat-elevation-z5" >


<button type="button" aria-label="Toggle sidenav" mat-icon-button    (click)="drawer.toggle()" ><mat-icon aria-label="Side nav toggle icon">menu</mat-icon></button>
 <span [routerLink]="['/home']">Isanama</span>
   
 <span class="example-spacer"></span>



<button mat-icon-button  [routerLink]="['/panier']"><mat-icon  matTooltip="Voir le panier"   >shopping_cart</mat-icon>({{ cartPaniers?.length }})</button>&nbsp;&nbsp;

  <ng-container *ngFor="let user  of userToDisplay  | async">
<button type="button" aria-label="Toggle sidenav" mat-icon-button  *ngIf="user.profil"  (click)="drawer2.toggle()" ><mat-icon aria-label="Side nav toggle icon" color="warn">settings</mat-icon></button></ng-container>
<!-- <button mat-icon-button (click)="onSignOut()"  *ngIf="isAuth"><mat-icon   matTooltip="Se déconnecter"   >power_settings_new</mat-icon></button>
<button mat-icon-button  *ngIf="!isAuth" [routerLink]="['auth/signin']"><mat-icon   matTooltip="Se connecter">power_settings_new</mat-icon></button> -->

</mat-toolbar>

 



<mat-drawer-container class="example-container" hasBackdrop="true" >
  <mat-drawer  #drawer  [ngStyle]="{'min-width':'320px', 'opacity': '1'}" mode="over"  style="z-index: 100!important;">
<!-- <mat-toolbar  color="warn">La boutique
<img  src="./assets/images/favicon.png"  [ngStyle]="{'max-height':'60px', 'margin-left':'140px','margin-top':'0px'}">
&nbsp;
</mat-toolbar> -->





<mat-nav-list  [ngStyle]="{'margin-top':'-10px'}">
<mat-divider></mat-divider>
  <a mat-list-item [routerLink]="['']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">home</mat-icon>
    <span matLine>Accueil</span>
  </a>

<mat-divider></mat-divider>
  <a mat-list-item [routerLink]="['/us']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">people</mat-icon>
    <span matLine>Qui sommes nous ?</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['/products']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">filter_vintage</mat-icon>
    <span matLine>La Boutique</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['/gallerie']" [ngStyle]="{'height':'60px'}"(click)="drawer.close()" >
    <mat-icon color="warn">photo</mat-icon>
    <span matLine>La Galerie</span>
  </a>
<mat-divider></mat-divider>


  <a mat-list-item [routerLink]="['/contact']" [ngStyle]="{'height':'60px'}"(click)="drawer.close()" >
    <mat-icon color="warn">mail</mat-icon>
    <span matLine>Nous contacter</span>
  </a>
<mat-divider></mat-divider>


</mat-nav-list>


<!-- 
<mat-toolbar  [ngStyle]="{'margin-top' : '0px','padding-left':'10px','height':'60px'}" color="warn">Mon panier

  
</mat-toolbar>
 -->


<mat-nav-list  [ngStyle]="{'margin-top':'-10px'}">


  <a mat-list-item [routerLink]="['/panier']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
<mat-icon  color="warn" matBadge="{{cartPaniers?.length}}" matBadgeColor="primary">shopping_cart</mat-icon>
    <span matLine>Panier</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['/politique']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">book</mat-icon>
    <span matLine>Politique Générale de Vente</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['moncompte']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">person</mat-icon>
    <span matLine>Mon compte</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['mescommandes']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon color="warn">shopping_basket</mat-icon>
    <span matLine>Mes commandes</span>
  </a>

<mat-divider></mat-divider>


</mat-nav-list>


<ng-container *ngIf="!isAuth">


<mat-nav-list  [ngStyle]="{'margin-top':'-10px'}">


  <a mat-list-item  [routerLink]="['auth/signin']" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon  color="warn">power_settings_new</mat-icon>
    <span matLine>Connexion</span>
  </a>



<mat-divider></mat-divider>

</mat-nav-list>





</ng-container>










<ng-container *ngIf="isAuth">


<mat-nav-list  [ngStyle]="{'margin-top':'-10px'}">


  <a mat-list-item  (click)="onSignOut()" [ngStyle]="{'height':'60px'}" (click)="drawer.close()">
    <mat-icon  color="warn">power_settings_new</mat-icon>
    <span matLine>Déconnexion</span>
  </a>



<mat-divider></mat-divider>

</mat-nav-list>





</ng-container>
<mat-toolbar color="accent" [ngStyle]="{'opacity': '0.75', 'color':'white','height':'50px','font-size' :'15px'}">Réalisation : &nbsp;
 <a [ngStyle]="{'font-size' :'15px','margin-top' :'2px'}"  href="https://www.linkedin.com/in/aentem">linkedin.com/in/aentem</a></mat-toolbar>
<mat-toolbar color="accent" [ngStyle]="{'opacity': '0.75', 'color':'white','height':'30px','font-size' :'15px'}"><a href="https://www.fiverr.com/share/d0z3k6"  [ngStyle]="{'margin-top' :'-25px'}">Besoin d'une application web/mobile ?</a></mat-toolbar>

  </mat-drawer>


 
  <mat-drawer  #drawer2  [ngStyle]="{'min-width':'320px', 'opacity': '1'}" mode="over" position="end" style="z-index: 100!important;">














<mat-nav-list  [ngStyle]="{'margin-top':'-10px'}">

  <a mat-list-item [routerLink]="['messages']" [ngStyle]="{'height':'60px'}" (click)="drawer2.close()">
    <mat-icon color="warn">mail</mat-icon>
    <span matLine>Messages reçus</span>
  </a>
<mat-divider></mat-divider>
  <a mat-list-item [routerLink]="['/admin']" [ngStyle]="{'height':'60px'}" (click)="drawer2.close()">
    <mat-icon color="warn">filter_vintage</mat-icon>
    <span matLine>Gestion des créations</span>
  </a>
<mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['commandesadmin']" [ngStyle]="{'height':'60px'}" (click)="drawer2.close()">
    <mat-icon color="warn">shopping_basket</mat-icon>
    <span matLine>Gestion des commandes</span>
  </a>
<mat-divider></mat-divider>
  <a mat-list-item [routerLink]="['userslist']" [ngStyle]="{'height':'60px'}" (click)="drawer2.close()">
    <mat-icon color="warn">people</mat-icon>
    <span matLine>Liste des utilisateurs</span>
  </a>
<mat-divider></mat-divider>


</mat-nav-list>

  </mat-drawer>

















  <mat-drawer-content class="main">




    
<router-outlet></router-outlet>

</mat-drawer-content>

</mat-drawer-container>
