import { Injectable } from '@angular/core';
import { CanActivate, Router,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { UsersService } from '../services/users.service';

@Injectable()
export class AdminGuardService implements CanActivate {

  constructor(private router: Router,public usersService: UsersService,) {  }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().onAuthStateChanged(
          (user) => {
            if(user) {

 this.usersService.getUserById(user.uid).subscribe((res: any) => {
              if( res[0].profil) {  resolve(true);}
else {
              
              this.router.navigate(['/auth', 'signin'], {queryParams: {return: state.url   }}    );
              resolve(false);
            }
  });


            
            } 

else {
              
              this.router.navigate(['/auth', 'signin'], {queryParams: {return: state.url   }}    );
              resolve(false);
            }

            
          }
        );
      }
    );
  }

}
