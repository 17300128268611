import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, interval, pipe } from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import { Panier} from '../models/panier.model';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import { AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/database';
import { Paniersave } from '../models/paniersave.model';



@Injectable({
  providedIn: 'root'
})

export class PanierService {


games: AngularFireList<any>;


  cartAdditionEmitter = new EventEmitter<Panier[]>(); // emitted for card and single panier, minicart listens to it
  cartTotalEmitter = new EventEmitter<number>(); // emitted for price total calculation on, addition, substraction, increase or removal
 

  private allPaniers: Panier[];
  private cartAddedPaniers: Panier[] = [];
  private cartTotal = 0;
  private selectedPanier: Panier;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
private database: AngularFireDatabase
  ) {  this.games = database.list('paniersaved');
}








  addToCart(panier: Panier) {
    var added = this.cartAddedPaniers.find(p => p.id === panier.id);
    added ? added.qty++ : this.cartAddedPaniers.push(panier);
    this.cartAdditionEmitter.emit(this.cartAddedPaniers);
    this.calculateCartTotal();
    this.cartTotalEmitter.emit(this.cartTotal);

  }

  getCartAddedPaniers() {
    return this.cartAddedPaniers;
  }

  calculateCartTotal() {
    this.cartTotal = 4;
    this.cartAddedPaniers.forEach(element => {
      this.cartTotal += element.price * element.qty;
    });
  }

  getCartTotal() {
    return this.cartTotal;
  }

  cartPanierManipulate(panier: Panier, increase: boolean = false) {
    const manipulatedPanier = this.cartAddedPaniers.find(mp => mp === panier);
    increase ? manipulatedPanier.qty++ : manipulatedPanier.qty--;
    this.calculateCartTotal();
    this.cartTotalEmitter.emit(this.cartTotal);
  }



  removeCartSingleItem(itemIndex: number) {
    // fixes a bug where multiple items are added to a cart if we cleared a cart when item had qty > 1
    this.cartAddedPaniers[itemIndex].qty = 1;

    const removedPanierName = this.cartAddedPaniers[itemIndex].name;
    this.cartAddedPaniers.splice(itemIndex, 1);
    this.cartAdditionEmitter.emit(this.cartAddedPaniers);
    this.calculateCartTotal();
    this.cartTotalEmitter.emit(this.cartTotal);

  }

  emptyCart() {
    // fixes a bug where multiple items are added to a cart if we cleared a cart when item had qty > 1
    for (const cp of this.cartAddedPaniers) { cp.qty = 1; }

    this.cartAddedPaniers = [];
    this.cartAdditionEmitter.emit(this.cartAddedPaniers);
    this.cartTotal = 0;
    this.cartTotalEmitter.emit(this.cartTotal);
    

  }


saveCart(newPaniersave:Paniersave) { this.games.push(newPaniersave); }


getCartByuid(key: string){
return this.database.list('/paniersaved', ref => ref.orderByChild("user_id").equalTo(key)).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data: Object = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
}));
   }

deletePlayer(key: string){
this.database.list('/paniersaved').remove(key);
}

  // calculateCartTotal() {
  //   this.cartTotal = 8;
  //   this.cartAddedPaniers.forEach(element => {
  //     this.cartTotal += element.price * element.qty;
  //   });
  // }


  }




