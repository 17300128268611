<div class="test">



<!-- <div  [ngStyle]="{ 'min-height':'40vh'}" >
 
  <div class="center">
<mat-card class="love" [ngStyle]="{ 'margin':'5px','opacity':'1','height':'250px'}">
    <div mat-card-avatar class="example-header-image2" ></div>     
    <div  class="example-header-image3" ></div>   
</mat-card>
<button  class="love2" mat-raised-button color="warn" [routerLink]="['/products']" [ngStyle]="{'margin':'5px', 'width':'98%', 'color': 'white', 'height':'70px', 'font-size' :'25px','opacity':'1'}"   >
              Accès à la Boutique
</button>

  </div>

</div> -->

 <mat-card  class="love3" [ngStyle]="{'margin-top':'80px','margin-left':'20px','margin-right':'20px'}">


 <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Isabelle et Thomas</mat-card-title>
        <mat-card-subtitle >Toutes nos créations sont uniques et réalisées avec passion.</mat-card-subtitle>
      </mat-card-header>

      <mat-card-actions [ngStyle]="{'margin-top':'10px','margin-left':'5px'}">     
        <button mat-flat-button color="accent" [routerLink]="['/us']" [ngStyle]="{'margin-top':'10px','color':'white'}">Qui sommes nous ?</button>&nbsp;
        <button mat-flat-button color="accent" [routerLink]="['/gallerie']" [ngStyle]="{'margin-top':'10px','color':'white'}">Galerie</button>&nbsp;
        <button mat-flat-button color="accent" [routerLink]="['/contact']" [ngStyle]="{'margin-top':'10px','color':'white'}">Nous écrire</button>&nbsp;
        <button   mat-flat-button color="warn" [routerLink]="['/products']"  [ngStyle]="{'margin-top':'10px','color':'white'}" >Accès à la Boutique</button>
      </mat-card-actions>

<mat-divider [ngStyle]="{'position':'relative','margin-top':'20px', 'margin-bottom':'30px'}"></mat-divider>


<button mat-mini-fab color="warn"   [ngStyle]="{'color':'white','position':'absolute','margin-top':'0px'}" >
              <mat-icon>favorite</mat-icon>
              </button>

      <h2 [ngStyle]="{'margin-left':'50px'}" >  Nos produits</h2>




        <div class="row" [ngStyle]="{  'margin-top':'20px','min-height':'20vh'}">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" *ngFor="let GametoDisplay  of  (GamesToDisplay | async)" [ngStyle]="{  'margin-bottom':'20px'}">
             
              <img  class="imag" src="{{GametoDisplay.imagePathSm}}"  [ngStyle]="{ 'width' :'100%', 'margin':'0px'}" (click)="gotoSingle(GametoDisplay.key)">
              <div>
                <div [ngStyle]="{  'margin-top':'5px'}">
                  <mat-card-title>{{GametoDisplay.name}}</mat-card-title>
            
                  <mat-divider [ngStyle]="{'position':'relative','margin-top':'10px', 'margin-bottom':'5px','max-width':'330px'}"></mat-divider>
                  <p class="text">   {{ (GametoDisplay.description.length>300)? (GametoDisplay.description | slice:0:300)+' ... ':(GametoDisplay.description) }}</p>
                </div>
              </div>
            </div>
        </div>

    </mat-card>






