import { Injectable, Input, EventEmitter  } from '@angular/core';
import { Subject } from 'rxjs';
import { Product } from '../models/product.model';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import { Observable, interval, pipe } from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class GamesService {

  constructor(private database: AngularFireDatabase) {
  this.games = database.list('products');
 this.backof = database.list('backoffice');



    }
searchEmitter = new EventEmitter<string>();
 filterTypeEmitter = new EventEmitter<string>();
  private filterBy = 'all';
  private search = '';
games: AngularFireList<any>;
backof: AngularFireList<any>;

CreateNewGame(newProduct:Product) { this.games.push(newProduct); }
Backof(newProduct:Product) { this.backof.push(newProduct); }


getGames(){

 return this.games.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

getGamesfav(){

 return this.database.list('/products', ref => {
    return ref.orderByChild('coeur').equalTo(true);}).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data: Object = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }


getGamesbycat(cat)

{

 return this.database.list('/products', ref => {
    return ref.orderByChild('type').equalTo(cat);}).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data: Object = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }




// Addplayer(id,userid){ this.database.object('games/' + id + '/Players_playing').push(userid); } 



UpdateUser(key: string, User){ this.database.list('/products').update(key, User); }



getSingleGame(id){
    return this.database.object('products/' + id).valueChanges();}




UpdateProducts(id, newqty){ this.database.object('/products/' + id + '/port').set(newqty); }

SetFalseProduct(id){ this.database.object('/products/' + id + '/dispo').set(false); }



getSingleProduct(key: string){
return this.database.list('/products', ref => ref.orderByKey().equalTo(key)).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data: Object = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }


 


DeleteProduct(key){this.database.list('/products').remove(key);
}

  searchFilter(searchValue: string) {
    this.search = searchValue;
    this.searchEmitter.emit(this.search);
  }

  setFilter(filterValue: string) {
    this.filterBy = filterValue;
    this.filterTypeEmitter.emit(this.filterBy);
  }
  getFilter() {
    return this.filterBy;
  }

  getSearchFilter() {
    return this.search;
  }


}






