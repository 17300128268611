import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import {switchMap, map} from 'rxjs/operators';
import { first } from 'rxjs/operators';
@Injectable()
export class AuthService {

userId: string;

  constructor( private db: AngularFireDatabase, public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe(
    user => { if(user) this.userId = user.uid;}
  )
  }

createNewUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
}

signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();

          },
          (error) => {
            reject(error);
          }
        );
      }
    );

}

signOutUser() {
    firebase.auth().signOut();
}

resetPassword(email: string) {
    var auth = firebase.auth();
    return auth.sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error))
  }

  async getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

getUserById(UserId: string){



return this.db.list('/users', ref => ref.orderByChild("id").equalTo(UserId)).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
   
    const data: Object  = a.payload.val();
    const key = a.payload.key;
    return {key, ...data} ;
  });
})); }

}