import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GamesService } from '../services/game.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],

})
export class WelcomeComponent implements OnInit {

GamesToDisplay;
Go : boolean = false;

constructor(
    private gamesService:GamesService,
    private meta: Meta,
    private router: Router,
    private titleService: Title) { }
 
ngOnInit(): void { 
this.GamesToDisplay = this.gamesService.getGamesfav();
this.titleService.setTitle('Isanama : Une Boutique de créations uniques réalisées artisanalement avec passion');
    this.meta.addTag({
      name: 'Isanama',
      content: 'Isanama créations'
    });
    this.meta.updateTag(
      {
        name: 'description',
        content: 'Nous travaillons de manière artisanale dans nos petits ateliers , avec le savoir faire acquis durant nos formations.' +
          ' Nos créations sont principalement composées de trois matériaux : tissus, papier et bois !! '
      });
}


     
gotoSingle(key) { this.router.navigate(['products',key]); }

}