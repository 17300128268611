import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';


const routes: Routes = [
 { path: '', redirectTo: 'home', pathMatch: 'full' },


{ path: 'home', component: WelcomeComponent },


  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then(m => m.SuccessModule)
  },


  {
    path: 'save',
    loadChildren: () => import('./savepanier/savepanier.module').then(m => m.SavePanierModule)
  },
  {
    path: 'panier',
    loadChildren: () => import('./panier/panier.module').then(m => m.PanierModule)
  },

  {
    path: 'us',
    loadChildren: () => import('./us/us.module').then(m => m.UsModule)
  },


  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },


  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./productsadmin/productsadmin.module').then(m => m.ProductsadminModule)
  },
  {
    path: 'commande',
    loadChildren: () => import('./commande/commande.module').then(m => m.CommandeModule)
  },
  {
    path: 'gallerie',
    loadChildren: () => import('./gallerie/gallerie.module').then(m => m.GallerieModule)
  },

  {
    path: 'mescommandes',
    loadChildren: () => import('./commandes/commandes.module').then(m => m.CommandesModule)
  },


   {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },


   {
    path: 'moncompte',
    loadChildren: () => import('./compte/compte.module').then(m => m.CompteModule)
  },

   {
    path: 'politique',
    loadChildren: () => import('./politique/politique.module').then(m => m.PolitiqueModule)
  },

   {
    path: 'userslist',
    loadChildren: () => import('./users/Users.module').then(m => m.UsersModule)
  },


   {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule)
  },

   {
    path: 'commandesadmin',
    loadChildren: () => import('./commandesadmin/commandesadmin.module').then(m => m.CommandesadminModule)
  },

   { path: '**', redirectTo: ''}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
