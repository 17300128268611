import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/database';
import { Observable } from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;


@Injectable({
  providedIn: 'root'
})
export class UsersService {

users: AngularFireList<any>;



constructor(private database: AngularFireDatabase)
{

this.users = database.list('users'); 
}

getUsers() { return this.users.snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
    const data = a.payload.val();
    const key = a.payload.key;
    return {key, ...data };
  });
})); }

CreateUser(newUser: User) { this.users.push(newUser); }


getUserById(UserId: string){



return this.database.list('/users', ref => ref.orderByChild("id").equalTo(UserId)).snapshotChanges().pipe(map(actions => {
  return actions.map(a => {
   
    const data: Object  = a.payload.val();
    const key = a.payload.key;
    return {key, ...data} ;
  });
})); }


UpdateUser(key: string, User){ this.database.list('/users').update(key, User); }


getSingleUser(id: string){return this.database.object('users/'+id).valueChanges();}




deletePlayer(key: string){
this.database.list('/users').remove(key);
}
}
 