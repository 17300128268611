
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';
import { User } from '../models/user.model';
import * as firebase from 'firebase/app';
import { PanierService } from '../services/panier.service';
import { startWith ,map, take, finalize } from 'rxjs/operators';
import { Observable,Subscription,throwError  } from 'rxjs';
import {NgForm} from '@angular/forms';
import { Panier } from '../models/panier.model';





@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  cartTotal: number;
 cartTotalSubscription: Subscription;
 cartPaniers: Panier[];
 cartAdditionSubscription: Subscription;
  isAuth: boolean= false;
id : string;
userToDisplay;
 _bookListUrl ='https://us-central1-isanama-1fd5f.cloudfunctions.net/sendMail?dest=a.entem@gmail.com';


constructor(
 
	 public authService: AuthService,
	 private router: Router,
	 private usersService : UsersService,

   private prodService: PanierService)


 {}









ngOnInit() {

this.cartPaniers = this.prodService.getCartAddedPaniers();
    this.cartAdditionSubscription = this.prodService.cartAdditionEmitter.subscribe(
      (paniers: Panier[]) => {
        this.cartPaniers = paniers;
      }
    );
    this.cartTotal = this.prodService.getCartTotal();
    this.cartTotalSubscription = this.prodService.cartTotalEmitter.subscribe(
      (cTotal: number) => {
        this.cartTotal = cTotal;
      }
    );
     firebase.auth().onAuthStateChanged(
      (user) => {
        if(user) {
          this.isAuth = true;
          var user = firebase.auth().currentUser;
          var name, email, photoUrl, uid, emailVerified;


if (user != null) {
  name = user.displayName;
  email = user.email;
  photoUrl = user.photoURL;
  emailVerified = user.emailVerified;
  uid = user.uid; 





}




          this.userToDisplay = this.usersService.getUserById(uid);



        } else {
          this.isAuth = false;
        }
      }
    );
 
  
  



  }



  ngOnDestroy() {
    this.cartAdditionSubscription.unsubscribe();
    this.cartTotalSubscription.unsubscribe();
  }



 onSignOut() { this.authService.signOutUser(); }

}
